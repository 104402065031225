import './App.css';
import { Button, ConfigProvider, Input, Space, theme } from 'antd';

import DemoForm from "./components/registration-form/registration-form.component";

function App() {
  return (
    <div className='MainDiv'>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: '#ff7900',
              // Alias Token
            },
          }}
  >
    <DemoForm/>
  </ConfigProvider>
    </div>

  );
}

export default App;
