import {useEffect, useState} from "react";
import {Form, Modal, Input, Button, Checkbox} from 'antd';
import { MaskedInput } from 'antd-mask-input';


import { ReactComponent as MprofiLogo } from '../../assets/mProfi_logo.svg';

import './registration-form.styles.scss';

const RECAPTCHA_SITE_KEY = `${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
const RCS_DEMO_URL = `${process.env.REACT_APP_RCS_DEMO_URL}`

const DemoForm = (props) => {
  const [checked, setChecked] = useState(false);
  const [orangeChecked, setOrangeChecked] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, function () {
      console.log("Script loaded!");
  });

  }, []);
  // Define layout for demo form
  const formItemLayout = {
    labelCol: { xs: { span: 20, offset:2 }, sm: { span: 20, offset:2  }, md: { span: 20, offset:2  }},
    wrapperCol: { xs: { span: 20, offset:2 }, sm: { span: 20, offset:2  }, md: { span: 20, offset:2  }},
    labelAlign: "right"
  };

  // Submit form function
  const submit_form = async(values) =>{
    const body_data = {
        "msisdn": '+' + values.msisdn.replace(/\D/g,''),
        "company_name": values.company_name,
        "g-recaptcha-response": values.token,
        "orange-agreement": orangeChecked,
    }

  // Send form data to backend  

  const response = await fetch(`${RCS_DEMO_URL}`, {
    method: 'POST',
    headers: {
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
      },
        'body': JSON.stringify(body_data)
    })
    .then(response => response.json())
    .then((data) => {
        if (data.provider === 'ORANGE' || data.provider==='T-MOBILE'){
          setIsSuccessModalOpen(true);
        } else {
          setIsFailModalOpen(true);
        }
        setLoading(false);
    }).catch((error) => {
      alert('Wystąpił błąd w trakcie wysyłania wiadomości')
        setLoading(false)
        console.log('EROR:', error);
    });
  };

  // Do this on sumbit button click
  const onFinish = async(values) => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {action: 'submit'}).then(token => {
        submit_form({'msisdn': values.msisdn, 'token': token, 'company_name': values.company_name});
      });
    });
  };

  // Do this on checkbox click  
  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const onOrangeCheckboxChange = (e) => {
    setOrangeChecked(e.target.checked);
  };

  const handleSuccessOk = () => {
    isSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const handleFailOk = () => {
    isFailModalOpen(false);
  };

  const handleFailCancel = () => {
    setIsFailModalOpen(false);
  };

  return (
    <div className="demo-form">
      <Modal title="Udało się," open={isSuccessModalOpen} onOk={handleSuccessOk} onCancel={handleSuccessCancel}
      footer={[
        <Button key="back" onClick={handleSuccessCancel}>
          Zamknij
        </Button>,
        <Button
          key="link"
          href="https://mprofi.pl/rich-communications-services-rcs-czyli-sms-2-0-co-to-jest/"
          type="primary"
          loading={loading}
          onClick={handleSuccessOk}
        >
          Dowiedz się więcej o RCS
        </Button>,
      ]}
      >
        <p>za chwilę dostaniesz przykładową wiadomość RCS. </p>
      </Modal>

      <Modal title="Nie udało się," open={isFailModalOpen} onOk={handleFailOk} onCancel={handleFailCancel}
      footer={[
        <Button key="back" onClick={handleFailCancel}>
          Zamknij
        </Button>,
        <Button
          key="link"
          href="https://mprofi.pl/rich-communications-services-rcs-czyli-sms-2-0-co-to-jest/"
          type="primary"
          loading={loading}
          onClick={handleFailOk}
        >
          Dowiedz się więcej o RCS
        </Button>,
      ]}
      >
        <p>nie udało nam się wysłać wiadomości RCS na Twój aparat. Kliknij przycisk poniżej, aby dowiedzieć się jakie możliwości daje ten nowy format multimedialnych wiadomości.</p>
      </Modal>

      {/* antd form header */}
      <div className="demo-form-header">
        <h1 className='header-text'>RCS DEMO</h1>
      </div>

      {/* antd form deffinition */}
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        layout='vertical'
        class='demo-form'
      >

      {/* msisdn field definition */}
        <p className="demo-form-label"> Podaj numer swojego telefonu</p>
        <Form.Item
          {...formItemLayout}
          name="msisdn"
          rules={[
            {
              required: true,
              message: 'Numer telefonu jest wymagany!'
            }
          ]}
        >
          <MaskedInput
            mask={
              '+48 000-000-000'
            }
            type='tel'
          />
        </Form.Item>

        {/* company name field definition */}
          <p className="demo-form-label"> Podaj nazwę swojej firmy (opcjonalne) </p>
          <Form.Item
            {...formItemLayout}
            name="company_name"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input/>
          </Form.Item>

        {/* agreements field definition */}
          <Form.Item
            {...formItemLayout}
            name="terms_and_condition"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Bez Twojej zgody nie wyślemy Ci żadnej wiadomości.')),
              },
            ]}
            style={{
              marginBottom:"1px",
              marginTop:"1px"
            }}
          >
            <Checkbox onChange={onCheckboxChange}><p style={{fontSize:"10px"}}>Akceptuję <a href={"https://mprofi.pl/regulamin-rodo/"}>warunki usługi </a> i <a href={"https://mprofi.pl/polityka-prywatnosci-rodo/"}>przetwarzania&nbsp;danych</a></p></Checkbox>
          </Form.Item>

          {/* agreements field definition */}
          <Form.Item
            {...formItemLayout}
            name="orange_checked"
            valuePropName="orangeChecked"
          >
            <Checkbox onChange={onOrangeCheckboxChange}> <p style={{fontSize:"10px"}}> wyrażam zgodę na przekazanie powyższych danych kontaktowych do <a href={"https://www.orange.pl/orange_polska.phtml?footerlink=true"}>Orange Polska S.A. </a> (opcjonalne)</p> </Checkbox>
          </Form.Item>

          {/* submit button definition */}
          <Form.Item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Button
              id="submit-button"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            >
              <b>Wyślij RCS</b>
            </Button>
          </Form.Item>

        </Form>

        {/* form footer */}
          <div className="demo-form-footer">
            <MprofiLogo className='logo'/>
          </div>
      </div>
  )

}

export default DemoForm